import React from 'react'
import { Link } from 'gatsby'
import { Container, Dropdown } from 'react-bootstrap'
import { header, header_inner, header_logo } from './style.module.scss'
import logo from '~img/logo.svg'
import Menu from '~components/Menu'

const Header = ({ siteTitle }) => (
  <Container as="header" className={header}>
    <div className={header_inner}>
      <div className={header_logo}>
        <Link to="/">
          <img width="84" height="59" src={logo} alt={siteTitle} />
        </Link>
      </div>
      <Menu variant="header" />
      <Dropdown className="dropdown--header">
        <Dropdown.Toggle as="button">
          <span className="dropdown-hamb">
            <span className="dropdown-hamb-icon"></span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu--header">
          <Menu variant="dropdown" />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  </Container>
)

export default Header
