import React from 'react'
import { useLocation } from '@reach/router'
import { Container } from 'react-bootstrap'
import { Cta } from '~components/Cta'
import logo from '~img/logo.svg'
import Menu from '~components/Menu'
import cn from 'classnames'
import * as s from './style.module.scss'

const Footer = ({ siteTitle }) => {
  const location = useLocation()
  const isCareersPage = location.pathname.includes('careers')
  return (
    <Container as="footer" className={cn(s.footer, { [s.careers]: isCareersPage })}>
      {!isCareersPage && <Cta />}
      <div className={s.footer_inner}>
        <div className={s.footer_logo}>
          <img width="84" height="59" src={logo} alt={siteTitle} />
        </div>
        <Menu variant="footer" />
      </div>
    </Container>
  )
}

export default Footer
