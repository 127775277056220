import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import { useForm } from '@formspree/react'
import {
  cta,
  cta_title,
  cta_form,
  cta_fields,
  cta_select,
  cta_textarea,
  cta_btn,
  cta_btn_text,
} from './style.module.scss'

export const Cta = () => {
  const [state, handleSubmit] = useForm('moqyveay')
  const [validated, setValidated] = useState(false)

  const onSubmit = (event) => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
    } else {
      handleSubmit(event).then((res) => {
        setValidated(false)
      })
    }

    setValidated(true)
  }

  return (
    <div className={cta}>
      <h2 className={cta_title}>Ready to go forward?</h2>
      <Form noValidate validated={validated} onSubmit={onSubmit} className={cta_form}>
        <div className={cta_fields}>
          <Form.Group>
            <Form.Label>How can we call you?</Form.Label>
            <Form.Control type="text" required />
          </Form.Group>
          <Form.Group className={cta_select}>
            <Form.Label>How can we help you?</Form.Label>
            <Form.Control as="select">
              <option>Issuance of Securities</option>
              <option>Construction & Engineering Litigation</option>
              <option>Real Estate Development & Real Estate Financing</option>
              <option>Listing</option>
              <option>Outbound investments and M&A</option>
              <option>Entering Southeast Asian Market</option>
              <option>Investment. Joint venture projects</option>
              <option>Finance. Asset Management</option>
              <option>Real Estate. PF</option>
              <option>Others</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>How can we contact you?</Form.Label>
            <Form.Control type="text" required />
          </Form.Group>
          <Form.Group className={cta_textarea}>
            <Form.Label>Your Message</Form.Label>
            <Form.Control as="textarea" rows={1} type="text" required />
          </Form.Group>
        </div>
        <button className={cta_btn} type="submit">
          <span className={cta_btn_text}>Send</span>
        </button>
      </Form>
    </div>
  )
}
