const MENU = [
  {
    name: 'Korean',
    link: '/korean',
  },
  {
    name: 'Asean',
    link: '/asean',
  },
  {
    name: 'Areas of Practice',
    link: '/practice',
  },
  {
    name: 'Team',
    link: '/team',
  },
  {
    name: 'News',
    link: '/news',
  },
  {
    name: 'Careers',
    link: '/careers',
  },
  {
    name: 'Contact',
    link: '/contact',
  },
]

export default MENU
