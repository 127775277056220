import React from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { Nav } from 'react-bootstrap'
import cn from 'classnames'

import MENU from './constants'

const Menu = ({ variant }) => {
  const location = useLocation()
  return (
    <Nav className={cn({ [`nav--${variant}`]: variant })} as="ul">
      {MENU.map(({ name, link }) => (
        <Nav.Item as="li" key={name}>
          <Nav.Link as={Link} to={link} active={location.pathname.includes(link)}>
            {name}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  )
}

export default Menu
